<template>
  <v-dialog scrollable transition="dialog-bottom-transition" width="99%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        text
        @click="fnShowInfoVisitaEnDialog(item.id)"
        color="primary"
        v-bind="attrs"
        v-on="on"
        >Ver</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar class="pl-5 pt-5 pb-5" color="primary"
          ><strong class="white--text"
            >Visita ID: {{ modal_item.id }}</strong
          ></v-toolbar
        >
        <v-card-text v-if="flag == 'cargando'">
          <div class="text-h2 pa-12">Cargando informacion de visita..</div>
          <v-progress-linear indeterminate color="teal"></v-progress-linear>
        </v-card-text>

        <v-card-text v-if="flag == 'vistaEncontrada'">
          <v-row>
            <!-- datos generales -->
            <v-col cols="7">
              <v-row>
                <!-- DATOS  DE LA SUPERVISORA -->
                <v-col cols="6"
                  ><v-subheader> Supervisora </v-subheader>

                  <v-divider></v-divider>

                  <table class="mb-2">
                    <tbody v-if="modal_item.supervisora">
                      <tr>
                        <td valign="top" class="td_titulo">Usuario:</td>
                        <td class="td_detalle">
                          <span style="white-space: nowrap"
                            ><v-icon small>mdi-cellphone</v-icon
                            >{{ modal_item.supervisora.alias }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Nombre:</td>
                        <td class="td_detalle">
                          {{ modal_item.supervisora.nombre_completo }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Sector:</td>
                        <td class="td_detalle">
                          {{ modal_item.supervisora.codigo }}
                        </td>
                      </tr>

                      <tr>
                        <td valign="top" class="td_titulo">División:</td>
                        <td class="td_detalle">
                          {{ modal_item.supervisora.division }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Región:</td>
                        <td class="td_detalle">
                          {{ modal_item.supervisora.region }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">
                          Agente de comercio:
                        </td>
                        <td class="td_detalle">
                          {{ modal_item.supervisora.tipo }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td valign="top" class="td_titulo mt-2">Usuario:</td>
                        <td class="td_detalle">
                          {{ modal_item.supervisora.alias }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo mt-2">Batería:</td>
                        <td
                          v-if="modal_item.supervisora.bateria != null"
                          class="td_detalle"
                          :title="getDate(modal_item.supervisora.bateria_fecha)"
                        >
                          {{ modal_item.supervisora.bateria }} %
                        </td>
                        <td
                          v-else
                          class="td_detalle"
                          title="Sin información disponible"
                        >
                          N/A
                        </td>
                      </tr>
                      <tr>
                        <td>GPS</td>

                        <td
                          v-if="modal_item.supervisora.gps_flag == 0"
                          :title="getDate(modal_item.supervisora.gps_fecha)"
                          class="red--text"
                        >
                          Apagado
                        </td>
                        <td
                          v-if="modal_item.supervisora.gps_flag == 1"
                          :title="getDate(modal_item.supervisora.gps_fecha)"
                          class="green--text"
                        >
                          Encendido
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
                <!-- /** FIN DATOS DE LA SUPERVISRA */ -->
                <!-- /** 
                DETALLE TBL DATOS DE LA CONSEJERA
             */ -->
                <v-col cols="5">
                  <v-subheader> Datos consejera </v-subheader>

                  <v-divider></v-divider>
                  <table class="mb-2">
                    <tbody>
                      <tr>
                        <td valign="top" class="td_titulo">Código:</td>
                        <td class="td_detalle">
                          {{ modal_item.consejera.codigo }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Nombre:</td>
                        <td class="td_detalle">
                          {{ modal_item.consejera.nombre_completo }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Dirección:</td>
                        <td class="td_detalle">
                          <p class="text-wrap">
                            {{ modal_item.consejera.direccion }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Teléfono:</td>
                        <td class="td_detalle">
                          {{ modal_item.consejera.telefono }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td valign="top" class="td_titulo">Sector:</td>
                        <td class="td_detalle" width="55">
                          <span mr-2>{{ modal_item.consejera.sector }} </span>
                        </td>
                        <td valign="top" class="td_titulo">División:</td>
                        <td class="td_detalle" width="90">
                          <span mr-2>{{ modal_item.consejera.division }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Región:</td>
                        <td colspan="2" class="td_detalle" width="120">
                          <span mr-2>{{ modal_item.consejera.region }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">Ultima visita:</td>
                        <td colspan="3" class="td_detalle" width="220">
                          <span mr-2>{{
                            fnFiltroFecha(modal_item.consejera.ultima_visita)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- /** 
                FIN DETALLE TBL DATOS DE LA CONSEJERA
                */ -->
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- DATOS DE LA VISITA -->
                  <v-subheader> Datos de la visita </v-subheader>

                  <v-divider></v-divider>
                  <table class="mb-2">
                    <tbody>
                      <tr>
                        <td valign="top" class="td_titulo">Fecha:</td>
                        <td class="td_detalle">
                          {{ fnFiltroFecha(modal_item.fecha) }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">
                          Registro de visita:
                        </td>
                        <td>
                          {{ modal_item.motivo_de_visita }}
                        </td>
                      </tr>

                      <tr>
                        <td valign="top" class="td_titulo">Comentarios:</td>
                        <td
                          v-if="
                            modal_item.observaciones != '' &&
                            modal_item.observaciones != null
                          "
                          class="td_detalle"
                        >
                          {{ modal_item.observaciones }}
                        </td>
                        <td v-else class="td_detalle">sin comentarios</td>
                      </tr>
                      <tr class="py-4">
                        <td valign="top" class="td_titulo">
                          Visita coordenadas:
                        </td>
                        <td class="td_detalle">
                          <strong
                            ><v-icon
                              :class="
                                fnGetColor(modal_item.motivo_de_visita_id)
                              "
                              >mdi-label-variant</v-icon
                            >
                            lat:</strong
                          >
                          {{ modal_item.lat }}, <strong>lng:</strong>
                          {{ modal_item.lng }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- FIN DATOS DE LA VISITA -->
                </v-col>
                <v-col>
                  <!-- DATOS DE PEDIDO -->
                  <v-subheader v-if="modal_item.pedido">
                    Datos de ultimo pedido
                  </v-subheader>

                  <v-divider v-if="modal_item.pedido"></v-divider>
                  <table class="mb-2" v-if="modal_item.pedido">
                    <tbody>
                      <tr>
                        <td valign="top" class="td_titulo">
                          Número de pedido:
                        </td>
                        <td>
                          {{ modal_item.pedido.ERP_DOC }}
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" class="td_titulo">
                          Fecha de entrega:
                        </td>
                        <td class="td_detalle">
                          {{
                            fnFiltroFecha_pedido(
                              modal_item.pedido.DELIVERY_DATE
                            )
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td valign="top" class="td_titulo">
                          Estado del pedido:
                        </td>
                        <td class="td_detalle">
                          {{ modal_item.pedido.STATUSTEXT }}
                        </td>
                      </tr>
                      <tr class="py-4">
                        <td valign="top" class="td_titulo">
                          Entrega coordenadas:
                        </td>
                        <td
                          class="td_detalle"
                          v-if="
                            modal_item.pedido.DELIVERY_LAT &&
                            modal_item.pedido.DELIVERY_LON
                          "
                        >
                          <strong
                            ><v-img
                              width="18"
                              style="margin-right: 1.2px"
                              class="d-inline-flex ml-1"
                              :src="require('@/assets/images/pedido-icon.png')"
                            ></v-img>

                            lat:</strong
                          >
                          {{ modal_item.pedido.DELIVERY_LAT }},
                          <strong>lng:</strong>
                          {{ modal_item.pedido.DELIVERY_LON }}
                        </td>
                        <td v-else>
                          <strong
                            ><v-img
                              width="18"
                              style="margin-right: 1.2px"
                              class="d-inline-flex ml-1"
                              :src="require('@/assets/images/pedido-icon.png')"
                            ></v-img>
                          </strong>

                          Sin coordenadas
                          {{ modal_item.pedido.DELIVERY_LON }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- DATOS DE PEDIDO -->
                </v-col>
              </v-row>
            </v-col>
            <!-- fin datos generales -->
            <!-- imagen -->
            <v-col cols="2">
              <v-subheader> Imagen </v-subheader>
              <v-divider></v-divider>
              <zoom-on-hover
                class="grey lighten-5"
                contain
                v-if="modal_item.image_dir"
                :scale="4"
                :img-normal="fnGetVisitaImagen(modal_item.image_dir)"
                :img-zoom="fnGetVisitaImagen(modal_item.image_dir)"
              ></zoom-on-hover>
            </v-col>
            <!-- fin imagen -->
            <!-- mapa -->
            <v-col cols="3">
              <v-subheader> Mapa </v-subheader>
              <v-divider></v-divider>
              <mapa
                :prop_markers="[modal_item]"
                :prop_visitadetalle="true"
                :prop_polyline_enable="false"
              />
            </v-col>
            <!-- fin mapa -->
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Cerrar ventana</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import mapa from '../components/mapa.vue';
import moment from 'moment';
export default {
  name: 'visitasDetalle_dialog',
  components: {
    mapa,
  },
  props: ['item'],
  data() {
    return {
      modal_item: {},
      flag: 'cargando',
      modal: false,
      visita: {},
    };
  },
  watch: {},
  methods: {
    ...mapActions('Visitas', {
      getVisitas: 'get',
    }),
    fnShowInfoVisitaEnDialog(id) {
      this.flag = 'cargando';
      console.log(id);
      this.getVisitas(id).then((result) => {
        console.log(result);
        this.modal_item = result;
        this.flag = 'vistaEncontrada';
      });
    },
    getDate(date) {
      return date ? moment(date).utc().format('DD MMMM YYYY hh:mm a') : '';
    },
    fnGetColor(localTipoVisita) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.id == localTipoVisita
      )[0].textColorClass;
    },
    fnFiltroFecha_pedido(fecha) {
      return fecha
        ? moment(fecha).format('DD') +
            ' de ' +
            moment(fecha).format('MMMM YYYY') +
            ' a las ' +
            moment(fecha).format('HH:mm a')
        : 'N/a';
    },
    fnFiltroFecha(fecha) {
      return fecha
        ? moment(fecha).format('DD') +
            ' de ' +
            moment(fecha).format('MMMM YYYY') +
            ' a las ' +
            moment(fecha).utc().format('HH:mm a')
        : 'N/a';
    },
    fnGetVisitaImagen(dir) {
      return process.env.VUE_APP_FEATHERS_SERVER + dir;
    },
  },
};
</script>
<style scoped>
.td_titulo {
  font-weight: 700 !important;
}
</style>
