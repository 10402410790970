<template>
  <v-container class="" fluid>
    <v-row>
      <v-col cols="9">
        <!-- /** INICIo */  -->
        <!-- <div :class="`text-caption`">Example Heading</div> -->

        <v-row align="center" :class="[prop_range ? 'px-3 mx-auto' : '']">
          <v-col cols="12" class="float-right">
            <v-card class="" elevation="" max-width="">
              <v-card-text>
                <h1>Total de visitas: {{ tbl_total }}</h1>
                <span
                  class="mt-3 text-caption"
                  v-if="superUserValidation == false"
                >
                  Agentes de comercio asignadas a tu usuario:
                  {{ fnGetString(tbl_supervisoras_parametrizadas) }}.
                </span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <download-excel
                  :fields="columnasEXCLsector"
                  :fetch="
                    () => {
                      return fnExportarXls();
                    }
                  "
                  :before-finish="finishDownload"
                  :before-generate="
                    () => {
                      return startDownload();
                    }
                  "
                  :type="'xls'"
                  :name="'Detalle de Visitas'"
                  ><v-btn
                    small
                    color="green darken-4"
                    class="white--text float-right"
                  >
                    Exportar
                    <v-icon right dark> mdi-download </v-icon>
                  </v-btn></download-excel
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="tbl_visitas"
              :items-per-page="5"
              class="elevation-3"
              disable-pagination
              :options.sync="options"
              :hide-default-footer="true"
              :item-key="tbl_visitas.id"
            >
              <!-- fecha en tabla -->
              <template v-slot:[`item.usuario_nombre`]="{ item }">
                <span
                  style="white-space: nowrap"
                  :title="`Usuario ${item.supervisora.nombre_completo}`"
                  ><v-icon small>mdi-cellphone</v-icon
                  >{{ item.supervisora.alias }}</span
                >
              </template>
              <!-- nombre de consejera -->
              <template v-slot:[`item.consejera_nombre`]="{ item }">
                <span
                  :title="item.consejera_nombre"
                  class="toLowerCaseCSS d-inline-block text-truncate"
                  style="max-width: 122px"
                  >{{ item.consejera_nombre }}</span
                >
              </template>
              <!-- nombre de consejera -->
              <!-- fecha en tabla -->
              <template v-slot:[`item.fecha`]="{ item }">
                {{ getDate(item.fecha) }}
              </template>
              <!-- fecha en tabla -->
              <template v-slot:[`item.ver`]="{ item }">
                <visitaDialog :item="item"></visitaDialog>
              </template>
            </v-data-table>
          </v-col>
          <v-col>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </v-col>
        </v-row>
        <!-- fion -->
      </v-col>
      <v-col cols="3">
        <v-card class="" elevation="" max-width="">
          <v-card-title> Parámetros de búsqueda </v-card-title>
          <v-card-text>
            <!-- SEARCH || mess -->
            <v-dialog
              ref="mesDialog"
              v-model="modalMes"
              :return-value.sync="dialogVarSelectedMes"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedMes"
                  label="Mes:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="dialogVarSelectedMes"
                scrollable
                type="month"
                show-adjacent-months
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalMes = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.mesDialog.save(dialogVarSelectedMes)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- SEARCH || mess -->

            <!-- /*** Dashboard select - dias */ -->
            <v-dialog
              ref="diaDialog"
              v-model="modalFechas"
              :return-value.sync="dialogVarSelectedDia"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDias"
                  label="Dias:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                locale="es"
                v-model="dialogVarSelectedDia"
                :range="prop_range"
                scrollable
                :min="dialogVarMinDia"
                :max="dialogVarMaxDia"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.diaDialog.save(dialogVarSelectedDia)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- /*** Dashboard select - dias */ -->

            <!-- MOTIVOS -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="search_motivo_de_visita_id"
              :items="localStoreMotivos"
              color="primary"
              item-text="label"
              item-value="id"
              label="Motivo de visita"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- MOTIVOS -->
            <!-- DIVISION -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchDivision"
              :items="itemsDivisiones"
              :loading="loadingDivisiones"
              :search-input.sync="searchSyncDivisiones"
              color="primary"
              :hide-selected="false"
              item-text="division"
              item-value="division"
              label="Divisiones"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- DIVISION -->
            <!-- REGION -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchRegion"
              :items="itemsRegiones"
              :loading="loadingRegiones"
              :search-input.sync="searchSyncRegiones"
              color="primary"
              :hide-selected="false"
              item-text="region"
              item-value="region"
              label="Regiones"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <!-- REGION -->
            <!-- SECTOR -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchSector"
              :items="itemsSectores"
              :loading="loadingSectores"
              :search-input.sync="searchSyncSectores"
              color="primary"
              :hide-selected="false"
              item-text="codigo"
              item-value="codigo"
              label="Sectores"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- SECTOR -->
            <!-- AGENTE DE COMERCIO -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchAgenteDeComercio"
              :items="$store.state.globalAgentesDeComercio"
              :loading="loadingAgenteDeComercio"
              color="primary"
              label="Agente de comercio"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
            <!-- AGENTE DE COMERCIO -->
            <!-- SUPERVISORA -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              v-model="searchSupervisora"
              :items="itemsSupervisoras"
              :loading="loadingSupervisoras"
              :search-input.sync="searchSyncSupervisoras"
              color="primary"
              item-text="nombre_completo"
              item-value="id"
              label="Usuario(s)"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
            <!-- SUPERVISORA -->
            <!-- ID DE REGISTRO -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchIds"
              :items="itemsIds"
              :loading="loadingIds"
              :search-input.sync="searchSyncIds"
              color="primary"
              :hide-selected="false"
              item-text="id"
              item-value="id"
              label="IDs de registros"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <!-- ID DE REGISTRO -->
            <!-- CONSEJERAS -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchConsejera"
              :items="itemsConsejeras"
              :loading="loadingConsejeras"
              :search-input.sync="searchSyncConsejeras"
              color="primary"
              item-text="nombre_completo"
              item-value="id"
              label="Consejera"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
            <!-- CONSEJERAS -->

            <!-- APLICAR PARAMETROS -->
            <v-btn
              id="Buscar parametros"
              @click="(page = 1), fnGetTblVisitas()"
              small
              color="blue-grey"
              class="white--text float-right"
            >
              Buscar
              <v-icon right dark> mdi-magnify </v-icon>
            </v-btn>
            <!-- APLICAR PARAMETROS -->
          </v-card-text>
          <v-card-actions>
            <v-btn text color=""> </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import visitaDialog from './visitasDetalleDialog.vue';

import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import JsonExcel from 'vue-json-excel';

export default {
  components: {
    visitaDialog: visitaDialog,
    downloadExcel: JsonExcel,
  },
  props: {
    prop_range: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //pagination
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      //
      columnasEXCLsector: {
        ID: 'id',
        Supervisora: 'usuario_nombre',
        Consejera: 'consejera_nombre',
        'Codigo de consejera': 'codigo_consejera',
        Latitud: 'lat',
        Longitud: 'lng',
        'Fecha visita': 'fecha',
        'Bandera nuevo teléfono': 'flag_new_tel',
        'Nuevo tel': 'new_tel',
        'Viejo tel': 'old_tel',
        'Motivo de visita': 'motivo_de_visita',
        Observaciones: 'observaciones',
        Sector: 'sector',
        Division: 'division',
        Region: 'region',
      },
      //supervisoras auntocomplete vars
      itemsSupervisoras: [],
      loadingSupervisoras: false,
      searchSyncSupervisoras: '',
      //agentes de comercio auntocomplete vars
      //itemsAgentesDeComercio: ,
      loadingAgenteDeComercio: false,
      searchAgenteDeComercio: [],
      //consejeras auntocomplete vars
      itemsConsejeras: [],
      loadingConsejeras: false,
      searchSyncConsejeras: '',
      //sectores auntocomplete vars
      itemsSectores: [],
      loadingSectores: false,
      searchSyncSectores: '',
      //divisiones auntocomplete vars
      itemsDivisiones: [],
      loadingDivisiones: false,
      searchSyncDivisiones: '',
      //sectores auntocomplete vars
      itemsRegiones: [],
      loadingRegiones: false,
      searchSyncRegiones: '',
      //search variables
      search_motivo_de_visita_id: [],
      searchSupervisora: [],
      searchConsejera: [],
      searchSector: [],
      searchDivision: [],
      searchRegion: [],
      //
      //

      searchIds: [],
      loadingIds: false,
      itemsIds: [],
      searchSyncIds: '',

      //
      selectedDias: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ],
      name: 'tabla detalle de visitas',
      //
      dialogVarSelectedMes: null,
      dialogVarSelectedDia: null,
      modalMes: false,
      modalFechas: false,
      search: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Supervisora (usuario)', value: 'usuario_nombre' },

        { text: 'Agente de comercio', value: 'tipo' },
        { text: 'Consejera', value: 'consejera_nombre' },
        { text: 'Motivo', value: 'motivo_de_visita' },
        { text: 'Sector', value: 'sector' },
        { text: 'División', value: 'division' },
        { text: 'Región', value: 'region' },

        { text: 'fecha', value: 'fecha' },
        { text: 'ver', value: 'ver' },
      ],
      tbl_visitas: [],
      tbl_total: 0,
      tbl_supervisoras_parametrizadas: [],
    };
  },
  computed: {
    selectedMes() {
      return this.dialogVarSelectedMes
        ? moment(this.dialogVarSelectedMes).format('MMM-YYYY')
        : moment().format('MMM-YYYY');
    },
    dialogVarMinDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    dialogVarMaxDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
    superUserValidation() {
      if (this.$store.state.auth.payload.usuario.sesion_rol == 0) {
        return true;
      } else {
        return false;
      }
    },
    localStoreMotivos() {
      return this.$store.state.visitas;
    },
  },
  watch: {
    options: {
      handler() {
        console.log('handler actived');
        this.fnGetTblVisitas();
      },
      deep: true,
    },
    searchSyncSupervisoras(newValue) {
      if (newValue && newValue != '') {
        if (this.searchSupervisora == null) {
          this.loadingSupervisoras = true;
          //levanta la funcion: fnGetAutocompleteSupervisoras
          this.fnGetAutocompleteSupervisoras();
        } else {
          let currentSupervisora = this.searchSupervisora.nombre_completo;
          if (newValue != currentSupervisora) {
            this.loadingSupervisoras = true;
            //levanta la funcion: fnGetAutocompleteSupervisoras
            this.fnGetAutocompleteSupervisoras();
          }
        }
      }
    },
    searchSyncConsejeras(newValue) {
      if (newValue && newValue != '') {
        if (this.searchConsejera == null) {
          this.loadingConsejeras = true;
          //levanta la funcion: fnGetAutocompleteConsejeras
          this.fnGetAutocompleteConsejeras();
        } else {
          let currentConsejera = this.searchConsejera.nombre_completo;
          if (newValue != currentConsejera) {
            this.loadingConsejeras = true;
            //levanta la funcion: fnGetAutocompleteConsejeras
            this.fnGetAutocompleteConsejeras();
          }
        }
      }
    },
    searchSyncSectores(newValue) {
      if (newValue && newValue != '') {
        this.loadingSectores = true;
        //levanta la funcion: fnGetAutocompleteSectores
        this.fnGetAutocompleteSectores();
      }
    },
    searchSyncDivisiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingDivisiones = true;
        //levanta la funcion: fnGetAutocompleteDivisiones
        this.fnGetAutocompleteDivisiones();
      }
    },
    searchSyncRegiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingRegiones = true;
        //levanta la funcion: fnGetAutocompleteRegiones
        this.fnGetAutocompleteRegiones();
      }
    },
    //
    searchSyncIds(newValue) {
      if (newValue && newValue != '') {
        this.loadingIds = true;
        this.fnGetAutocompleteIds();
      }
    },
    dialogVarSelectedMes(newValue) {
      if (newValue) {
        this.selectedDias = [
          moment(newValue).startOf('month').format('DD-MM-YYYY'),
          moment(newValue).endOf('month').format('DD-MM-YYYY'),
        ];
        this.dialogVarSelectedDia = [
          moment(newValue).startOf('month').format('YYYY-MM-DD'),
          moment(newValue).endOf('month').format('YYYY-MM-DD'),
        ];
      }
    },
    dialogVarSelectedDia(newValue) {
      this.selectedDias = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
  },
  methods: {
    ...mapActions('Visitas', {
      findVisitas: 'find',
    }),
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
    }),
    ...mapActions('Consejeras', {
      findConsejeras: 'find',
    }),
    ...mapActions('ReporteResumenConsejerasPorRegion', {
      findRRCPR: 'find',
    }),
    getDate(date) {
      return date ? moment(date).utc().format('DD MMM  HH:mm a') : '';
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    getParamsTblVisitas() {
      /**
       * configuración de parametros
       */
      const params = {};
      //fechas para feathers

      let split1 = this.selectedDias[0].split('-');

      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1, 17, 59, 59);

      let split2 = this.selectedDias[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 17, 59, 59);

      //fechas para feathers
      if (this.searchIds.length == []) {
        params['fecha'] = {
          $gte: firstDay,
          $lte: lastDay,
        };
      }
      console.log('param fechas');
      console.log(split1[2]);
      console.log(split1[1] - 1);
      console.log(split1[0] - 1);
      console.log(firstDay);

      let firstday2 = new Date('2022-04-30T00:00:00-06:00');
      console.log(firstday2);

      console.log(split2[2]);
      console.log(split2[1] - 1);
      console.log(split2[0]);
      console.log(lastDay);

      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      params['$sort'] = { fecha: 0 };

      /** parametrización de supervisoras asignadas a usuario administrativo */
      if (this.searchSupervisora.length >= 1) {
        let arrayOfIds = this.searchSupervisora.map((a) => a.id);
        params['id_usuario'] = { $in: arrayOfIds };
      }
      if (this.searchAgenteDeComercio.length >= 1) {
        params['tipo'] = { $in: this.searchAgenteDeComercio };
      }
      if (this.search_motivo_de_visita_id.length >= 1) {
        params['motivo_de_visita_id'] = {
          $in: this.search_motivo_de_visita_id,
        };
      }
      if (this.searchConsejera.length >= 1) {
        let arrayOfIds = this.searchConsejera.map((a) => a.codigo);
        params['id_consejera'] = { $in: arrayOfIds };
      }
      if (this.searchSector.length >= 1) {
        params['sector'] = { $in: this.searchSector };
      }
      // else if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
      //   params['sector'] = {
      //     $in: this.$store.state.auth.payload.codigos_supervisoras,
      //   };
      // }
      if (this.searchDivision.length >= 1) {
        params['division'] = { $in: this.searchDivision };
      }
      if (this.searchRegion.length >= 1) {
        params['region'] = { $in: this.searchRegion };
      }
      //
      if (this.searchIds.length >= 1) {
        params['id'] = { $in: this.searchIds };
      }
      /**
       *
       * retorno de parametros
       */

      return params;
    },
    getParamsTblVisitasExcl() {
      /**
       * configuración de parametros
       */
      const params = {};
      //fechas para feathers

      let split1 = this.selectedDias[0].split('-');
      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1, 17, 59, 59);

      let split2 = this.selectedDias[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 17, 59, 59);

      //fechas para feathers
      params['fecha'] = {
        $gte: firstDay,
        $lte: lastDay,
      };

      params['$limit'] = 15000;
      params['$sort'] = { fecha: 0 };

      if (this.searchSupervisora.length >= 1) {
        let arrayOfIds = this.searchSupervisora.map((a) => a.id);
        params['id_usuario'] = { $in: arrayOfIds };
      }
      if (this.search_motivo_de_visita_id.length >= 1) {
        params['motivo_de_visita_id'] = {
          $in: this.search_motivo_de_visita_id,
        };
      }

      if (this.searchAgenteDeComercio.length >= 1) {
        params['tipo'] = { $in: this.searchAgenteDeComercio };
      }
      /** xls parametrización de supervisoras asignadas a usuario administrativo */
      if (this.searchConsejera.length >= 1) {
        let arrayOfIds = this.searchSupervisora.map((a) => a.codigo);
        params['id_consejera'] = { $in: arrayOfIds };
      }
      if (this.searchSector.length >= 1) {
        params['sector'] = { $in: this.searchSector };
      }
      // else if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
      //   params['sector'] = {
      //     $in: this.$store.state.auth.payload.codigos_supervisoras,
      //   };
      // }
      if (this.searchDivision.length >= 1) {
        params['division'] = { $in: this.searchDivision };
      }
      if (this.searchRegion.length >= 1) {
        params['region'] = { $in: this.searchRegion };
      }
      /**
       *
       * retorno de parametros
       */

      return params;
    },
    getParamasAutoCompleteSupervisoras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSupervisoras
       */
      const params = {};
      params['$select'] = [
        'id',
        'nombre_completo',
        'codigo',
        'alias',
        'tipo',
        'division',
        'region',
      ];
      params['region'] = { $ne: '' };
      if (this.searchSyncSupervisoras != '') {
        params['$or'] = [
          {
            codigo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            nombre_completo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            alias: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
        ];
      }

      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['codigo'] = {
          $in: this.$store.state.auth.payload.codigos_supervisoras,
        };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutoCompleteConsejeras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteConsejeras
       */
      const params = {};
      params['$select'] = ['nombre_completo', 'codigo'];
      if (this.searchSyncConsejeras != '') {
        params['$or'] = [
          {
            codigo: { $like: '%' + this.searchSyncConsejeras + '%' },
          },
          { nombre_completo: { $like: '%' + this.searchSyncConsejeras + '%' } },
        ];
      }

      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['fk_id_supervisora'] = {
          $in: this.$store.state.auth.payload.codigos_supervisoras,
        };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteSectores() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSectores
       */
      const params = {};
      params['$select'] = ['codigo'];
      params['$limit'] = 3;
      params['$sort'] = { codigo: 1 };

      if (this.searchSyncSectores != '') {
        params['codigo'] = { $like: '%' + this.searchSyncSectores + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteDivisiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteDivisiones
       */
      const params = {};
      params['$select'] = ['division'];
      params['$limit'] = 3;
      params['$sort'] = { division: 0 };

      if (this.searchSyncDivisiones != '') {
        params['division'] = { $like: '%' + this.searchSyncDivisiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteRegiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};
      params['$select'] = ['region'];
      params['$limit'] = 3;
      params['$sort'] = { region: 1 };

      if (this.searchSyncRegiones != '') {
        params['region'] = { $like: this.searchSyncRegiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    fnGetTblVisitas() {
      //
      console.log(' a la verga que chinga ', this.getParamsTblVisitas());
      this.findVisitas({
        query: this.getParamsTblVisitas(),
      }).then((result) => {
        this.tbl_total = result.total;
        this.tbl_visitas = result.data;
        this.totalPages = Math.ceil(
          parseInt(result.total) / parseInt(this.pageSize)
        );
      });
      //
    },
    fnGetAutocompleteSupervisoras() {
      //
      this.findUsuarios({
        query: this.getParamasAutoCompleteSupervisoras(),
      }).then((result) => {
        result.data.forEach((supervisora) => {
          supervisora.nombre_completo =
            supervisora.alias +
            ' - ' +
            supervisora.tipo +
            ' - ' +
            supervisora.division +
            ' - ' +
            supervisora.region;
        });
        this.loadingSupervisoras = false;
        this.itemsSupervisoras = result.data;
      });
      //
    },
    fnGetAutocompleteConsejeras() {
      //
      this.findConsejeras({
        query: this.getParamasAutoCompleteConsejeras(),
      }).then((result) => {
        result.data.forEach((consejera) => {
          consejera.nombre_completo =
            consejera.codigo + ' ' + consejera.nombre_completo;
        });
        this.loadingConsejeras = false;
        this.itemsConsejeras = result.data;
      });
      //
    },
    fnGetAutocompleteSectores() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteSectores(),
      }).then((result) => {
        this.loadingSectores = false;
        this.itemsSectores = result.data;

        console.log(result.data);
      });
      //
    },
    fnGetAutocompleteDivisiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteDivisiones(),
      }).then((result) => {
        this.loadingDivisiones = false;
        this.itemsDivisiones = result.data;
      });
      //
    },
    fnGetAutocompleteRegiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteRegiones(),
      }).then((result) => {
        console.log(result.data);
        this.loadingRegiones = false;
        this.itemsRegiones = result.data;
      });
      //
    },
    //
    fnGetAutocompleteIds() {
      //
      this.findVisitas({
        query: this.getParamasAutocompleteIds(),
      }).then((result) => {
        console.log(result.data);
        this.loadingIds = false;
        this.itemsIds = result.data;
      });
      //
    },
    getParamasAutocompleteIds() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};

      console.log(' le siynchos ', this.searchSyncIds);

      if (this.searchSyncIds != '') {
        params['id'] = this.searchSyncIds;
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    //
    fnExportarXls() {
      let visitas = this.findVisitas({
        query: this.getParamsTblVisitasExcl(),
      }).then((result) => {
        console.log(result.data);
        return result.data;
      });
      return visitas;
    },
    startDownload() {},
    finishDownload() {},
    supAutoText: (item) => item.nombre_completo + ' — ' + item.codigo,
    handlePageChange(value) {
      this.page = value;
      this.fnGetTblVisitas();
    },
    fnGetString(object) {
      // prettier-ignore
      let string1=  object.map(function (a) {
            return a.nombre_completo;
          });

      string1 = string1.join(', ');
      return string1;
    },
  },

  mounted() {
    // this.fnGetTblVisitas();
  },
};
</script>
<style></style>
<style scoped>
.toLowerCaseCSS {
  text-transform: lowercase !important;
  font-size: 10px;
}
.btn-excel {
  cursor: pointer;
  padding: 2px 6px 2px 6px;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  border: #389466 1px solid;
  border-radius: 5px;
}
.btn-excel:hover {
  box-shadow: 1px 4px #888888;
}
.btn-excel:active {
  background-color: #74c977 !important;
  box-shadow: 1px 4px #888888;
}
</style>
